import axios from "axios";
export default {
  namespaced: true,
  state: {
    all_units: [],
    pending_units: [],
  },
  getters: {
    all_units(state) {
      return state.all_units;
    },
    units(state) {
      return state.units;
    },
    unit_status(state) {
      return state.unit_status;
    },
    pending_units(state) {
      return state.pending_units;
    },
  },
  mutations: {
    SET_PENDING_UNITS(state, pending_units) {
      state.pending_units = pending_units;
    },
    SET_ALL_UNITS(state, all_units) {
      state.all_units = all_units;
    },
    SET_UNIT_STATUS(state, unit_status) {
      state.unit_status = unit_status;
    },
    ADD_UNIT(state, unit) {
      state.all_units.unshift(unit);
    },
    SET_UNITS(state, units) {
      state.units = units;
    },
    UPDATE_UNIT(state, unit) {
      var index = state.all_units.findIndex((a) => a.id == unit.id);
      state.all_units.splice(index, 1, unit);
    },
    DELETE_UNIT(state, unit_id) {
      let filtered = state.all_units.filter((u) => {
        return u.id != unit_id;
      });
      state.all_units = filtered;
    },
  },
  actions: {
    async get_pending_units({ commit }) {
      let response = await axios.get("/unit_status/Pending");
      commit("SET_PENDING_UNITS", response.data);
    },
    async get_all_units({ commit }) {
      let response = await axios.get("/units");

      commit("SET_ALL_UNITS", response.data);
    },
    async get_unit_status({ commit }, type) {
      let response = await axios.get("unit_status/" + type);
      commit("SET_UNIT_STATUS", response.data);
    },
    async add_unit({ commit }, unit) {
      let response = await axios.post("/unit", unit);
      commit("ADD_UNIT", response.data);
    },
    async get_units({ commit }, customer_id) {
      let response = await axios.get("/units/" + customer_id);
      commit("SET_UNITS", response.data);
    },
    async update_unit({ commit }, unit) {
      let response = await axios.put("/unit", unit);
      commit("UPDATE_UNIT", response.data);
    },
    async delete_unit({ commit }, unit) {
      await axios.delete("/unit/" + unit.id);
      commit("DELETE_UNIT", unit.id);
    },
  },
};

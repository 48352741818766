<template>
  <div>
    <v-btn @click="dialog = true" text small
      ><v-icon>mdi-text-box-search-outline</v-icon>Price Check</v-btn
    >
    <v-dialog v-model="dialog" persistent width="1200">
      <v-card>
        <v-card-title
          >Price Check Modal <v-spacer></v-spacer>

          <v-btn @click="dialog = false"> Close</v-btn></v-card-title
        >
        <v-card-text>
          <div>
            <v-text-field
              v-model="search_item"
              label="Search Item"
              prepend-icon="mdi-magnify"
            ></v-text-field>
            <v-data-table
              :items="items"
              :headers="items_headers"
              :search="search_item"
            >
              <template v-slot:item.action="{ item }">
                <v-btn icon color="primary" @click="open_item_profile(item)"
                  ><v-icon>mdi-list-box</v-icon></v-btn
                >
              </template>
              <template v-slot:item.item_name="{ item }">
                <span v-if="item.is_vat" class="green--text">{{
                  item.item_name
                }}</span>
                <span v-else>{{ item.item_name }}</span>
              </template>
              <template v-slot:item.warranty="{ item }">
                {{ item.warranty.warranty_count }} -
                {{ item.warranty.warranty_duration }}
              </template>
              <template v-slot:item.quantity="{ item }">
                <span
                  class="red--text font-weight-bold"
                  v-if="item.quantity == 0"
                >
                  Out of Stock</span
                >
                <span v-else>{{ item.quantity }}</span>
              </template>
            </v-data-table>
          </div>
          <p class="caption">priceCheckModal.vue</p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Lazy loaded components -->
    <item-profile-modal
      :item="selected_item"
      v-if="item_profile_modal"
      @close="item_profile_modal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ItemProfileModal: () => import("../components/Item/itemProfileModal.vue"),
  },
  data() {
    return {
      selected_item: [],
      item_profile_modal: false,
      search_item: "",
      search_service: "",
      button_text: "Items",
      dialog: false,
      loading: false,
      items_headers: [
        { text: "Action", value: "action", width: "50" },
        { text: "ID", value: "id", width: "50" },
        { text: "Item Name", value: "item_name", width: "250" },
        { text: "Item Description", value: "item_description" },

        { text: "Selling Price", value: "selling_price" },
        {
          text: "Batch ID",
          value: "import_batch.batch_description",
          width: "100",
        },
        { text: "Warranty", value: "warranty" },
        { text: "Quantity", value: "quantity" },
      ],
      services_headers: [
        { text: "ID", value: "id" },
        { text: "Service Name", value: "service_name" },
        { text: "Service Amount", value: "service_amount" },
      ],
    };
  },
  methods: {
    open_item_profile(item) {
      this.selected_item = item;
      this.item_profile_modal = true;
    },
    change_button_text() {
      this.button_text == "Items"
        ? (this.button_text = "Services")
        : (this.button_text = "Items");
    },
    submit() {
      return;
    },
    ...mapActions({
      get_items: "item/get_items",
      get_services: "service/get_services",
    }),
  },
  computed: {
    ...mapGetters({
      items: "item/items",
    }),
  },
  created() {
    if (this.items.length <= 0) {
      this.get_items();
    }
  },
};
</script>

<style lang="scss" scoped></style>
